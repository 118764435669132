<template>
  <div>
    <h1 class="font-medium text-lg mb-20 px-16 sm:px-0">New user</h1>

    <div class="flex flex-col sm:flex-row items-start">
      <div class="flex-1 order-2 sm:order-1 w-full sm:w-auto">
        <create-user :loading="buttonLoading" @on-create-user="onCreateUser" />
      </div>

      <el-button
        type="primary"
        :loading="buttonLoading"
        class="ml-auto sm:ml-24 mb-20 sm:mb-0 mr-16 sm:mr-0 order-1 sm:order-2"
        @click="$router.push({ name: 'AdminUsers' })"
      >
        Back to user list
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserService from '@/services/user.service'

const CreateUser = () => import('@/components/admin-users/CreateUser')
const ElButton = () => import('@/components/shared/ELButton')

export default {
  name: 'CreateUserPage',

  components: { CreateUser, ElButton },

  data () {
    return {
      buttonLoading: false
    }
  },

  created () {
    this.loading(false)
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    onCreateUser (userData) {
      this.buttonLoading = true

      UserService.createAdminUsers(userData)
        .then(res => {
          console.log(res)
          this.buttonLoading = false
          this.showNotification({ type: 'success', message: 'User created.' })
          this.$router.push({ name: 'AdminUsers' })
        })
        .catch(() => {
          this.buttonLoading = false
        })
    }
  }
}
</script>
