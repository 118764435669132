import { render, staticRenderFns } from "./CreateUser.vue?vue&type=template&id=670d8101"
import script from "./CreateUser.vue?vue&type=script&lang=js"
export * from "./CreateUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports