import $axios from '@/core/api.config'
import { generateRequestParams } from '@/core/helper-functions'

class UserService {
  getAdminUsers (params = {}) {
    return $axios.get('/admin/users', {
      params: generateRequestParams(params)
    })
  }

  getAdminUserById (userId) {
    return $axios.get(`/admin/users/${userId}`)
  }

  editAdminUser (userData) {
    return $axios.put(`/admin/users/${userData.id}`, userData)
  }

  updateAdminUserPassword (userData) {
    return $axios.post('/admin/users/reset-password', userData)
  }

  createAdminUsers (userData) {
    return $axios.post('/admin/users', userData)
  }
}

export default new UserService()
